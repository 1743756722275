import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
// import Vuex from "vuex";
import ReportView from "views/reports/show.vue";

document.addEventListener("turbo:load", function () {
  if (document.getElementById("report-view")) {
    window.newReportView = new Vue({
      el: "#report-view",
      // store,
      components: {
        ReportView,
      },
    });
  }
});
