<template lang="html">
  <div>
    <deliverables-report
      ref="deliverablesReport"
      :linechart="lineChart"
      :resource="reportable"
      :report="report"
      :resource-path="reportablePath"
      :tenant-logo="tenantLogo"
      :editable="editable"
      :deliverables="deliverables"
      :lazy-load="lazyLoadCards"
      :expand-stories="expandStories"
      :pdf-report="pdfReport"
    />
  </div>
</template>

<script>
/* eslint func-names: ["error", "never"] */
// import Api2Pdf from "api2pdf";
// import puppeteer from "puppeteer";

import Vue from "vue/dist/vue.esm";
import { mapState } from "vuex";
import store from "store";
import _ from "lodash";
import Toasted from "vue-toasted";
import NProgress from "nprogress";
import DeliverablesReport from "views/deliverables/report.vue";

// const a2pClient = new Api2Pdf("4b3c10ec-dd1d-4afb-81cb-356a46ae3d8d");

// const $ = window.jQuery;

Vue.use(Toasted, {
  position: "bottom-right",
  duration: 2000,
});

export default {
  components: {
    DeliverablesReport,
  },
  store,
  props: {
    initialReport: {
      type: Object,
    },
    initialDeliverables: {
      type: Array,
    },
    reportable: {
      type: Object,
    },
    reportablePath: {
      type: String,
    },
    tenantLogo: {
      type: String,
    },
    isEditable: {
      type: Boolean,
    },
    accessToken: {
      type: String,
    },
    lazyLoadCards: {
      type: Boolean,
      default: true,
    },
    expandStories: {
      type: Boolean,
      default: false,
    },
    pdfReport: {
      type: Boolean,
      default: false,
    },
    linechart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const $this = this;
    return {
      lineChart: $this.linechart,
    };
  },
  computed: {
    ...mapState("report", {
      report: "report",
      deliverables: "deliverables",
      editable: "editable",
    }),
  },
  methods: {
    setEditing(val) {
      localStorage.setItem("isEditing", val);
      this.isEditing = val;
    },
    toggleEditing() {
      const value = !this.isEditing;
      this.setEditing(value);
    },
    share() {
      this.setEditing(false);
      window.$.get(`/reports/report/${this.report.id}/share`);
    },
    // eslint-disable-next-line prefer-arrow-callback
    save: _.debounce(function () {
      if (!this.editable) {
        return;
      }
      const $this = this;
      NProgress.start();
      // eslint-disable-next-line prefer-arrow-callback
      $this.$store.dispatch("report/save", $this.report).then(function () {
        if ($this.report.id) {
          window.history.pushState({}, "", `/reports/${$this.report.id}`);
          NProgress.done();
          Vue.toasted.success("Saved");
        }
      });
    }, 500),
  },
  watch: {
    "report.name": {
      handler(val) {
        if (val) {
          this.save();
        }
      },
    },
    "report.sections.description": _.debounce(function (val, old) {
      if (!old) return;
      this.save();
    }, 1000),
    "report.sections.logo": {
      handler() {
        if (this.report.id) {
          this.save();
        }
      },
      deep: true,
    },
    "report.sections.tenant_logo": {
      handler() {
        if (this.report.id) {
          this.save();
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.initialDeliverables) {
      this.$store.dispatch("report/setDeliverables", this.initialDeliverables);
    }
    if (this.initialReport) {
      this.$store.dispatch("report/setAccessToken", this.accessToken);
      this.$store.dispatch("report/show", this.initialReport);
    }
    this.$store.dispatch("report/setEditable", this.isEditable);
  },
  updated() {
    this.$nextTick(() => {
      if (window.iframely) window.iframely.load();
    });
  },
};
</script>

<style lang="scss" scoped>
@import "nprogress/nprogress.css";

.section {
  position: relative;
  clear: both;
}

.section .richText img {
  margin-left: 1em;
  margin-right: 1em;
}

.isDragging .section:hover,
.section:hover .delete {
  visibility: visible;
}
/* .delete {
  visibility: hidden;
  z-index: 1000;
} */

.handle {
  z-index: 1000;
}

.section .card.hidden {
  opacity: 0.35;
}
.section .card .corner.label {
  visibility: hidden;
}
.section .card:hover .corner.label {
  visibility: visible;
}

.sortable-drag {
}

.vue-grid-item.isEditing {
  border: 1px dashed #ccc;
}
.vue-grid-item .vue-resizable-handle {
  visibility: hidden;
}
.vue-grid-item:hover .vue-resizable-handle {
  visibility: visible;
}

.vue-grid-item.isEditing .vue-resizable-handle {
  visibility: visible;
}

.isDragging .section,
.ssortable-drag {
  max-height: 200px;
  min-height: 30px;
  overflow: hidden;
  border: 1px dashed #ccc;
}
.isDragging .section:before,
.ssortable-drag:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(transparent 150px, white);
  z-index: 9999999999999;
}

.sortable-chosen {
  border: 3px dashed #ccc !important;
}
</style>
